.pdf {
  &__page {
    margin-bottom: 4rem;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    canvas {
      max-width: 100%;
      height: auto !important;
      margin: auto;
    }

    .react-pdf__Page__textContent {
      max-width: 100%;
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .pager-button {
    &:disabled {
      .arrow-icon {
        color: #dfe1e4;
      }
    }
  }

  &.pdf--vertical {
    .pdf__wrapper {
      width: 100%;
    }
    .pdf__page {
      canvas {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }
}
